@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap');

@import './conf/_colors';
@import './conf/_variables';

@mixin main-background() {
    &::before {
        content: '';
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-size: cover;
        will-change: transform;
        z-index: -1;
    }
}

*,
*:after,
*:before {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    position: relative;
    min-width: 320px;
    height: 100vh;
    overflow: hidden;
}

body {
    font: 14px/16px $font-family;
    color: $default-text;
    background-color: $body-bg;
    margin: 0;
    height: 100%;

    div {
        &:focus {
            outline: none;
        }
    }
}

.no-flex-shrink {
    flex-shrink: 0;
}

.filter-padding-fix {
    padding: 4px;
}

.filter-padding-fix-left {
    padding: 4px 4px 4px 2rem;
}

body.mobile {
    background: none;

    .body-bg {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-attachment: inherit;
        background-color: $body-bg;
    }

    &.blur-theme .body-bg {
        @include main-background();
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html {
        overflow: hidden;
        height: 100%;
    }

    body {
        overflow: auto;
        height: 100%;
    }
}

a {
    transition: color 0.5s ease;
    outline: 0 !important;
    text-decoration: none;
}

.body-bg {
    display: none;
}

.heading {
    font-size: 1.5em;
    color: $primary;

    button {
        position: relative;
        bottom: 7px;
    }
}

.icon-button {
    svg {
        margin-right: 6px;
    }
}

.form-control {
    margin-bottom: 12px !important;
}

.growing-grey-background {
    background-color: #fafafa;
    flex: 1;
}

.axis {
    line,
    path {
        stroke: $border;
        shape-rendering: crispEdges;
    }

    text {
        font-family: $font-family !important;
        fill: $default-text;
        font-size: 12px;
    }

    &.axis--unit {
        fill: $border-dark;
    }
}

.grid {
    .tick {
        line {
            stroke: #d6d6d6;
        }
    }
}
.small-icon-button {
    height: 100%;
    padding: 0;
    svg {
        margin: 0;
    }
}
.title,
form label,
h1,
h2,
h3,
h4,
h5,
h6,
.MuiTypography-body1SemiBold,
.MuiTypography-body2SemiBold,
.MuiTypography-body1Bold,
.MuiTypography-body2Bold,
li[role='menuitem'] span {
    /* TODO: remove this (and then add uppercasing everywhere, good luck have fun)
    ** it adds an empty space after the text everywhere in the app on Firefox */
    &:first-letter {
        text-transform: uppercase;
    }
}

.page-title {
    margin-top: 12px !important;
    margin-left: 24px !important;
}

.mui-fixed {
    ul {
        li {
            svg {
                margin-right: 1rem;
            }
        }
    }
}

tbody {
    tr {
        td.MuiTableCell-root {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }
}

// We need this so that tooltip are above autocomplete in modals
div[role='tooltip'] {
    z-index: 10000;
}
@media print {
    body div#hubspot-messages-iframe-container {
        display: none !important;
    }
}
